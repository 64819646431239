import styled from 'styled-components';

export const NewFormulaPipeVolume = styled.div`
  padding: 20px;
  background: var(--white);
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.1);
  display: flex;

  .section-pipeline-form--left-side {
    flex: 4;
    padding: 20px;
    position: relative;

    .section-pipeline-form--left-row {
      margin-bottom: 24px;

      .section-pipeline-form--row-title {
        h4 {
          font-size: var(--font-size-20);
          color: var(--darkest);
        }
      }

      .section-pipeline-form--row-content {
        display: flex;
        margin-top: 14px;

        & > div {
          flex: 1;
        }

        .section-pipeline-form--input {
          width: 100%;
          margin-right: 20px;

          .text-field-input {
            font-size: var(--font-size-18) !important;
            color: var(--grey-6);
          }
        }

        button {
          margin-top: 6px;
        }
      }
    }

    .section-pipeline-form--label {
      font-size: var(--font-size-18);
      color: var(--grey-8);
      width: calc(100% - 40px);
      text-align: center;
      position: absolute;
      bottom: 0;
      margin-bottom: 10px;

      a {
        color: var(--grey-8);
        text-decoration: underline;
      }
    }
  }

  .section-pipeline-form--right-side {
    flex: 5;
    background: var(--grey-1);
    border: 1px solid var(--grey-4);
    border-radius: 4px;
    padding: 20px 24px;
    margin-left: 20px;

    .section-pipeline-form--right-title {
      margin-bottom: 20px;

      h4 {
        font-size: var(--font-size-20);
        color: var(--darkest);
      }
    }

    .section-pipeline-form--right-content {
      .section-pipeline-form--right-row {
        margin-bottom: 20px;

        .section-pipeline-form--right-row-title {
          margin-bottom: 20px;

          h4 {
            font-size: var(--font-size-20);
            font-weight: var(--font-weight-500);
            color: var(--dark);
          }
        }

        .section-pipeline-form--right-row-content {
          display: flex;
          margin-bottom: 14px;

          .section-pipeline-form--right-row-field {
            display: flex;
            padding: 0 18px;
            flex: 1;

            .section-pipeline-form--input {
              .text-field-input {
                max-width: 130px;
                font-size: var(--font-size-18) !important;
                color: var(--dark);
              }
            }

            .section-pipeline-form--label {
              font-size: var(--font-size-18);
              color: var(--dark);
              margin: 0 0 0 10px;
              line-height: 58px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 0;

    .section-pipeline-form--left-side {
      padding: 30px 24px;

      .section-pipeline-form--left-row {
        .section-pipeline-form--row-title {
          h4 {
            font-size: var(--font-size-18);
          }
        }

        .section-pipeline-form--row-content {
          .section-pipeline-form--input {
            .text-field-input {
              font-size: var(--font-size-16) !important;
            }
          }

          button {
            padding: 11px 24px;
          }
        }
      }

      .section-pipeline-form--label {
        position: relative;
      }
    }

    .section-pipeline-form--right-side {
      margin: 0 12px 18px;

      .section-pipeline-form--right-content {
        .section-pipeline-form--right-row {
          .section-pipeline-form--right-row-content {
            .section-pipeline-form--right-row-field {
              .section-pipeline-form--label {
                line-height: 48px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .section-pipeline-form--left-side {
      .section-pipeline-form--left-row {
        .section-pipeline-form--row-content {
          flex-direction: column;

          .section-pipeline-form--input {
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
    }

    .section-pipeline-form--right-side {
      .section-pipeline-form--right-content {
        .section-pipeline-form--right-row {
          .section-pipeline-form--right-row-content {
            flex-direction: column;
            .section-pipeline-form--right-row-field {
              margin-bottom: 5px;

              .section-pipeline-form--input {
                width: 100%;

                .text-field-input {
                  max-width: 100%;
                }
              }

              .section-pipeline-form--label {
                min-width: 65px;
              }
            }
          }
        }
      }
    }
  }
`;

export const DescriptionContainer = styled.div`
  max-width: 1046px;
  margin: 0 auto;
  margin-top: 70px;
  padding: 0 28px;

  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;

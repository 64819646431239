import React, { useState } from 'react';
import { useFormik } from 'formik';
import Select from 'components/Core/Select';
import TextField from 'components/Core/TextField';
import Button from 'components/Core/Button';
import HyperLink from 'components/Core/HyperLink';
import GatedSubscribeForm from 'components/Tools/GatedSubscribeForm';
import { getFormValid } from 'utils/formUtils';
import { NewFormulaPipeVolume } from '../styles';
import { FormContainer } from '../../styles';
import { MeasureUnits } from '../utils/content';

const PipeVolumeForm = ({ backgroundImg, title, description, gatedForm, ...otherProps }) => {
  const [gateFormCookieIncrease, setGateFormCookieIncrease] = useState(0);

  const checkValidate = (value) =>
    value && value !== '' && value.match(/^[0-9.]+$/) && parseFloat(value) > 0;

  const formik = useFormik({
    initialValues: {
      diameterMeas: 1,
      lengthPipeMeas: 1,
    },
    validate: (values) => {
      return {
        ...(!checkValidate(values.diameterPipe) && { diameterPipe: 'Pipe Diameter is invalid' }),
        ...(!checkValidate(values.lengthPipe) && { lengthPipe: 'Pipe Length is invalid' }),
      };
    },
    onSubmit: (values) => {
      calcPipeAll();
    },
  });

  const [pipeResult, setPipeResult] = useState({
    pipeVolume: 0,
    pipeVolumeIn: 0,
    pipeVolumeGal: 0,
    pipeVolumeLit: 0,
    pipeWeightLbs: 0,
    pipeWeightKg: 0,
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);
  };

  const calcPipeAll = () => {
    const values = { ...formik.values };

    const diameterMeas = values.diameterPipe * values.diameterMeas; // diameter value with choosed measure unit
    const radius = diameterMeas / 2;
    const lengthMeas = values.lengthPipe * values.lengthPipeMeas;
    const pipeVolume = 3.1415 * radius * radius * lengthMeas;
    const pipeVolumeCm = (pipeVolume * 1000).toFixed(1);

    setPipeResult({
      pipeVolume: pipeVolumeCm,
      pipeVolumeIn: (pipeVolumeCm * 0.00006103).toFixed(4),
      pipeVolumeGal: (pipeVolumeCm * 0.0000002641).toFixed(6),
      pipeVolumeLit: (pipeVolumeCm * 0.000001).toFixed(6),
      pipeWeightLbs: (pipeVolumeCm * 0.000001 * 2.204).toFixed(6),
      pipeWeightKg: (pipeVolumeCm * 0.000001).toFixed(6),
    });

    setGateFormCookieIncrease(gateFormCookieIncrease + 1);
  };

  const formValid = getFormValid({ ...formik });

  return (
    <FormContainer {...otherProps}>
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container">
          <NewFormulaPipeVolume>
            <div className="section-pipeline-form--left-side">
              <GatedSubscribeForm gatedForm={gatedForm} increaseCookie={gateFormCookieIncrease}>
                <div className="section-pipeline-form--left-row">
                  <div className="section-pipeline-form--row-title">
                    <h4>Pipe Diameter</h4>
                  </div>
                  <div className="section-pipeline-form--row-content">
                    <TextField
                      id="diameterPipe"
                      type="number"
                      className="section-pipeline-form--input"
                      values={formik.values}
                      formValid={formValid}
                      handleChange={onHandleChange}
                      showOnlyFormError
                    />
                    <Select
                      id="diameterMeas"
                      nameType="diameter"
                      className="section-pipeline-form--select"
                      placeholder="Choose measure unit"
                      options={MeasureUnits}
                      values={formik.values}
                      handleChange={onHandleChange}
                      width="100%"
                    />
                  </div>
                </div>

                <div className="section-pipeline-form--left-row">
                  <div className="section-pipeline-form--row-title">
                    <h4>Pipe Length</h4>
                  </div>
                  <div className="section-pipeline-form--row-content">
                    <TextField
                      id="lengthPipe"
                      type="number"
                      className="section-pipeline-form--input"
                      values={formik.values}
                      formValid={formValid}
                      handleChange={onHandleChange}
                      showOnlyFormError
                    />
                    <Select
                      id="lengthPipeMeas"
                      nameType="lengthPipeMeas"
                      className="section-pipeline-form--select"
                      formValid={formValid}
                      placeholder="Choose measure unit"
                      options={MeasureUnits}
                      values={formik.values}
                      handleChange={onHandleChange}
                    />
                  </div>
                </div>

                <Button shadow={false} onClick={() => formik.submitForm()} width="100%">
                  Calculate
                </Button>
              </GatedSubscribeForm>

              <p className="section-pipeline-form--label">
                Learn how we calculated this result{' '}
                <HyperLink href="#section-tool-page-content--container">below</HyperLink>.
              </p>
            </div>

            <div className="section-pipeline-form--right-side">
              <div className="section-pipeline-form--right-title">
                <h4>Results</h4>
              </div>

              <div className="section-pipeline-form--right-content">
                <div className="section-pipeline-form--right-row">
                  <div className="section-pipeline-form--right-row-title">
                    <h4>Volume:</h4>
                  </div>
                  <div className="section-pipeline-form--right-row-content">
                    <div className="section-pipeline-form--right-row-field">
                      <TextField
                        className="section-pipeline-form--input"
                        type="text"
                        value={pipeResult.pipeVolume}
                        readonly
                        showOnlyFormError
                      />
                      <p className="section-pipeline-form--label">cu mm</p>
                    </div>
                    <div className="section-pipeline-form--right-row-field">
                      <TextField
                        className="section-pipeline-form--input"
                        type="text"
                        value={pipeResult.pipeVolumeIn}
                        readonly
                        showOnlyFormError
                      />
                      <p className="section-pipeline-form--label">cu in</p>
                    </div>
                  </div>
                  <div className="section-pipeline-form--right-row-content">
                    <div className="section-pipeline-form--right-row-field">
                      <TextField
                        className="section-pipeline-form--input"
                        type="text"
                        value={pipeResult.pipeVolumeGal}
                        readonly
                        showOnlyFormError
                      />
                      <p className="section-pipeline-form--label">gallons</p>
                    </div>
                    <div className="section-pipeline-form--right-row-field">
                      <TextField
                        className="section-pipeline-form--input"
                        type="text"
                        value={pipeResult.pipeVolumeLit}
                        readonly
                        showOnlyFormError
                      />
                      <p className="section-pipeline-form--label">liters</p>
                    </div>
                  </div>
                </div>

                <div className="section-pipeline-form--right-row">
                  <div className="section-pipeline-form--right-row-title">
                    <h4>Weight:</h4>
                  </div>
                  <div className="section-pipeline-form--right-row-content">
                    <div className="section-pipeline-form--right-row-field">
                      <TextField
                        className="section-pipeline-form--input"
                        type="text"
                        value={pipeResult.pipeWeightLbs}
                        readonly
                        showOnlyFormError
                      />
                      <p className="section-pipeline-form--label">lbs</p>
                    </div>
                    <div className="section-pipeline-form--right-row-field">
                      <TextField
                        className="section-pipeline-form--input"
                        type="text"
                        value={pipeResult.pipeWeightKg}
                        readonly
                        showOnlyFormError
                      />
                      <p className="section-pipeline-form--label">kg</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </NewFormulaPipeVolume>
        </div>
      </div>
    </FormContainer>
  );
};

export default PipeVolumeForm;

export const MeasureUnits = [
  {
    label: 'Inches',
    value: 2.54,
  },
  {
    label: 'Foot',
    value: 30.48,
  },
  {
    label: 'Yard',
    value: 91.44,
  },
  {
    label: 'Millimetre',
    value: 0.1,
  },
  {
    label: 'Centimeters',
    value: 1,
  },
  {
    label: 'Meters',
    value: 100,
  },
];
